<template>
  <!-- leftbar-tab-menu -->
  <div
    id="sidebar"
    v-if="userProfile.email && !this.currentUrl.includes('/changepassword')"
  >
    <div class="logo">
      <img src="@/../public/assets/images/Android.png" alt="Logo" />
    </div>
    <div
      class="d-flex justify-content-between flex-column navigations"
      v-if="!hasAdminPageAccess(userProfile)"
    >
      <ul class="list-unstyled">
        <li class="icon-hov">
          <router-link
            to="/dashboard"
            :class="currentUrl === '/dashboard' ? 'active' : ''"
          >
            <DashboardIcon />
            Dashboard
          </router-link>
        </li>
        <!-- <li class="icon-hov">
          <router-link
            to="/analytics"
            :class="this.currentUrl == '/analytics' ? 'active' : ''"
          >
            <AnalyticsIcon />
            Analytics
          </router-link>
        </li>
        <li class="icon-hov">
          <router-link
            to="/Cart"
            :class="this.currentUrl.includes('/Cart') ? 'active' : ''"
          >
            <CartIcon />
            Cart
          </router-link>
        </li> -->
        <li class="icon-hov">
          <router-link
            to="/Customers"
            :class="this.currentUrl.includes('/Customers') ? 'active' : ''"
          >
            <MembersIcon />
            Customers
          </router-link>
        </li>
        <li class="icon-hov">
          <router-link
            to="/InventoryTable"
            :class="this.currentUrl.includes('/InventoryTable') ? 'active' : ''"
          >
            <MembersIcon />
            Inventory
          </router-link>
        </li>
        <!-- <li class="icon-hov">
          <router-link
            to="/Audience"
            :class="this.currentUrl.includes('/Audience') ? 'active' : ''"
          >
            <AudienceIcon />
            Audience
          </router-link>
        </li> -->
        <!-- <li class="icon-hov">
          <router-link
            to="/Email"
            :class="this.currentUrl.includes('/Email') ? 'active' : ''"
          >
          <EmailIcon />
            Email
          </router-link>
        </li> -->
      </ul>
      <ul class="list-unstyled bottom-navigation">
        <li>
          <router-link to="/">
            <div class="user">
              <div class="image profile-image">
                <img
                  :src="
                    generateAvatar(
                      userProfile?.email?.slice(0, 1)?.toUpperCase(),
                      '#5e66fa',
                      '#F0F1F2'
                    )
                  "
                  alt="User"
                  v-if="!userProfile.profile_image_url"
                />
                <img :src="userProfile?.profile_image_url" alt="User" v-else />
              </div>
              <span class="name">{{ userProfile.email }}</span>
              <span>{{ getUserRole(userProfile) }}</span>
            </div>
          </router-link>
        </li>

        <li class="icon-hov" v-if="getUserRole(userProfile) != 'Worker'">
          <router-link
            :to="{ name: 'Admin' }"
            :active-class="currentUrl === '/Admins' ? 'active' : ''"
          >
            <AdminIcon />
            Settings
          </router-link>
        </li>
      </ul>
    </div>
    <div class="d-flex justify-content-between flex-column navigations" v-else>
      <ul class="list-unstyled">
        <li class="icon-hov">
          <router-link
            to="/Companies"
            :class="this.currentUrl.includes('/Companies') ? 'active' : ''"
          >
            <OrganizationIcon />
            Companies
          </router-link>
        </li>
        <li class="icon-hov">
          <router-link
            to="/Users"
            :class="this.currentUrl.includes('/Users') ? 'active' : ''"
          >
            <MembersIcon />
            Users
          </router-link>
        </li>
        <li class="icon-hov" v-if="getUserRole(userProfile) == 'Super Admin'">
          <router-link
            to="/AllAdmins"
            :class="this.currentUrl.includes('/AllAdmins') ? 'active' : ''"
          >
            <AdminIcon />
            Admins
          </router-link>
        </li>
      </ul>
      <ul class="list-unstyled bottom-navigation">
        <li>
          <router-link to="/">
            <div class="user">
              <div class="image profile-image">
                <img
                  :src="
                    generateAvatar(
                      userProfile?.email.slice(0, 1).toUpperCase(),
                      '#5e66fa',
                      '#F0F1F2'
                    )
                  "
                  alt="User"
                  v-if="!userProfile?.profile_image_url"
                />
                <img :src="userProfile?.profile_image_url" alt="User" v-else />
              </div>
              <span class="name">{{ userProfile.email }}</span>
              <span>{{ getUserRole(userProfile) }}</span>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <!-- end leftbar-tab-menu-->
</template>

<script>
import { mapGetters } from "vuex";
import DashboardIcon from "../icons/dashboard.vue";
// import AnalyticsIcon from "../icons/analytics.vue";
// import CartIcon from "../icons/cart.vue";
import MembersIcon from "../icons/members.vue";
// import AudienceIcon from "../icons/audience.vue";
import AdminIcon from "../icons/admins.vue";
import OrganizationIcon from "../icons/organization.vue";
import { UserRoleMixin } from "@/Core/mixins/UserRoleMixin";
// import EmailIcon from "../icons/email.vue";

export default {
  name: "MainMenu",
  mixins: [UserRoleMixin],
  components: {
    DashboardIcon,
    // AnalyticsIcon,
    // CartIcon,
    MembersIcon,
    // AudienceIcon,
    AdminIcon,
    OrganizationIcon,
    // EmailIcon
  },
  computed: {
    ...mapGetters("user", { userProfile: "userProfile" }),
  },
  // created() {
    // console.log(
    //   this.currentUrl,
    //   "/Cart/Recommendations",
    //   this.currentUrl, window.location.pathname ,this.currentUrl.includes('/Cart')
    // );
  // },
  created() {
    this.currentUrl = this.$route.path;
  },
  methods: {
    generateAvatar(text, foregroundColor, backgroundColor) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = 200;
      canvas.height = 200;

      // Draw background
      context.fillStyle = backgroundColor;
      context.fillRect(0, 0, canvas.width, canvas.height);

      // Draw text
      context.font = "bold 100px Assistant";
      context.fillStyle = foregroundColor;
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillText(text, canvas.width / 2, canvas.height / 2);

      return canvas.toDataURL("image/png");
    },
  },
  data: function () {
    return {
      currentUrl: "",
    };
  },
};
</script>

<style>
.nav-left-bar {
  padding-top: 25px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .navigations {
    overflow-x: auto;
    overflow-y: auto;
  }
  .list-unstyled li {
    padding: 0 0 !important;
  }
  .list-unstyled li a {
    color: #303e67;
    padding: 10px 0;
  }
  .list-unstyled li .active {
    color: #5f66fa !important;
    background: #f6f6f7 !important;
    width: 100%;
  }
  .list-unstyled li svg:hover {
    stroke: #5f66fa !important;
    fill: #5f66fa !important;
  }
  .list-unstyled li .active svg {
    stroke: #5f66fa !important;
    fill: #5f66fa !important;
  }
  .profile-image {
    border-radius: 100%;
  }
</style>
