<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <MainMenu />
    <TopBar
      title="Dashboard"
      :author="{
        home: 'Home',
        cart: 'Dashboard',
      }"
    />
    <div class="main-wrp">
      <div class="dashboard-block-section">
        <div class="row">
            <div class="col">
              <div class="dashboard-block-box">
                <div class="d-flex align-items-center">
                  <img class="mr-3" src="@/../public/assets/images/active-customers.svg" alt="Logo" />
                  <p class="m-0">Active Customers</p>
                </div>
                <span>{{ generalMetrics.totalMembers }}</span>
              </div>
            </div>
            <div class="col">
              <div class="dashboard-block-box">
                <div class="d-flex align-items-center">
                  <img class="mr-3" src="@/../public/assets/images/articles.svg" alt="Logo" />
                  <p class="m-0">Articles</p>
                </div>
                <span>{{ generalMetrics.totalArticles }}</span>
              </div>
            </div>
            <div class="col">
              <div class="dashboard-block-box">
                <div class="d-flex align-items-center">
                  <img class="mr-3" src="@/../public/assets/images/average.svg" alt="Logo" />
                  <p class="m-0">Average CLV</p>
                </div>
                <span><sub>{{ default_currency }}</sub> {{
                  generalMetrics?.get_avg_clv_value?.avg_clv_value?.toFixed(0) *
                    default_clv_year || 0
                }}</span>
                <div
                  class="bottom__title"
                  v-if="generalMetrics.get_avg_clv_value?.diffrence"
                >
                  <DownArrow
                    v-if="generalMetrics.get_avg_clv_value?.diffrence < 0"
                  />
                  <UpArrow v-else />
                  <span
                    :class="
                      generalMetrics.get_avg_clv_value?.diffrence < 0
                        ? 'red-text'
                        : 'green-text'
                    "
                    >{{
                      generalMetrics.get_avg_clv_value?.diffrence?.toFixed(2) ||
                      0
                    }}%
                  </span>
                  <span class="gray-text">from {{ last_month_last_date() }}</span>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="dashboard-block-box">
                <div class="d-flex align-items-center">
                  <img class="mr-3" src="@/../public/assets/images/median.svg" alt="Logo" />
                  <p class="m-0">Median CLV</p>
                </div>
                <span><sub>{{ default_currency }}</sub> {{
                  generalMetrics?.get_median_clv_value?.median_clv_value?.toFixed(
                    0
                  ) * default_clv_year || 0
                }}</span>
                <div
                class="bottom__title"
                v-if="generalMetrics.get_median_clv_value?.diffrence"
              >
                <DownArrow
                  v-if="generalMetrics.get_median_clv_value?.diffrence < 0"
                />
                <UpArrow v-else />
                <span
                  :class="
                    generalMetrics.get_median_clv_value?.diffrence < 0
                      ? 'red-text'
                      : 'green-text'
                  "
                  >{{
                    generalMetrics.get_median_clv_value?.diffrence?.toFixed(2) ||
                    0
                  }}%
                </span>
                <span class="gray-text">from {{ last_month_last_date() }}</span>
              </div>
              </div>
            </div>
            <div class="col">
              <div class="dashboard-block-box">
                <div class="d-flex align-items-center">
                  <img class="mr-3" src="@/../public/assets/images/order.svg" alt="Logo" />
                  <p class="m-0">Average Order Value</p>
                </div>
                <span><sub>{{ default_currency }}</sub> {{
                  generalMetrics?.get_avg_order_value?.avg_receipt_value?.toFixed(
                    0
                  ) || 0
                }}</span>
                <div
                class="bottom__title" 
                v-if="generalMetrics?.get_avg_order_value?.diffrence"
              >
                <DownArrow
                  v-if="generalMetrics?.get_avg_order_value?.diffrence < 0"
                />
                <UpArrow v-else />
                <span
                  :class="
                    generalMetrics.get_avg_order_value?.diffrence < 0
                      ? 'red-text'
                      : 'green-text'
                  "
                  >{{
                    generalMetrics.get_avg_order_value?.diffrence?.toFixed(2) ||
                    0
                  }}%
                </span>
                <span class="gray-text">from {{ one_month_ago_date() }}</span>
              </div>
              </div>
            </div>
        </div>
      </div>
      <div class="dashboard-tabs-table-section">
          <div class="row">
            <div class="col-lg-6 mb-3">
                <div class="dashboard-tabs-card">
                    <h2>Customers</h2>
                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="restock-now-tab" data-toggle="pill" href="#restock-now" role="tab" aria-controls="restock-now" aria-selected="true">Restock</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="churn-potential-tab" data-toggle="pill" href="#churn-potential" role="tab" aria-controls="churn-potential" aria-selected="false">Churn Potential</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="most-re-occuring-tab" data-toggle="pill" href="#most-re-occuring" role="tab" aria-controls="most-re-occuring" aria-selected="false">Most Re-occuring</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="most-valuable-tab" data-toggle="pill" href="#most-valuable" role="tab" aria-controls="most-valuable" aria-selected="false">Most Valuable</a>
                      </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                      <div class="tab-pane fade show active" id="restock-now" role="tabpanel" aria-labelledby="restock-now-tab">
                          <div class="table-contant-wrp">
                            <CustomerNeedRestock />
                          </div>
                      </div>
                      <div class="tab-pane fade" id="churn-potential" role="tabpanel" aria-labelledby="churn-potential-tab">
                        <div class="table-contant-wrp">
                          <ChurnPotentialCutomers />
                        </div>
                      </div>
                      <div class="tab-pane fade" id="most-re-occuring" role="tabpanel" aria-labelledby="most-re-occuring-tab">
                          <div class="table-contant-wrp">
                            <ReoccuringCustomersCard />
                          </div>
                      </div>
                      <div class="tab-pane fade" id="most-valuable" role="tabpanel" aria-labelledby="most-valuable-tab">
                        <div class="table-contant-wrp">
                          <MostValuableCustomersCard />
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mb-3">
              <div class="dashboard-tabs-card">
                  <h2>Articles</h2>
                  <ul class="nav nav-pills" id="pills-tab" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" id="top-trending-tab" data-toggle="pill" href="#top-trending" role="tab" aria-controls="top-trending" aria-selected="true">Top Trending</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="re-stocking-central-tab" data-toggle="pill" href="#re-stocking-central" role="tab" aria-controls="re-stocking-central" aria-selected="false">Re-stocking (Central)</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="re-stocking-national-tab" data-toggle="pill" href="#re-stocking-national" role="tab" aria-controls="re-stocking-national" aria-selected="false">Re-stocking (National)</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="top-selling-tab" data-toggle="pill" href="#top-selling" role="tab" aria-controls="top-selling" aria-selected="false">Top Selling</a>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="top-trending" role="tabpanel" aria-labelledby="top-trending-tab">
                        <div class="table-contant-wrp">
                          <TopTrendingArticles />
                        </div>
                    </div>
                    <div class="tab-pane fade" id="re-stocking-central" role="tabpanel" aria-labelledby="re-stocking-central-tab">
                      <div class="table-contant-wrp">
                        <ArticleRestockingCentralWarehouse />
                      </div>
                    </div>
                    <div class="tab-pane fade" id="re-stocking-national" role="tabpanel" aria-labelledby="re-stocking-national-tab">
                      <div class="table-contant-wrp">
                        <ArticleRestockingNationalWarehouse />
                      </div>
                    </div>
                    <div class="tab-pane fade" id="top-selling" role="tabpanel" aria-labelledby="top-selling-tab">
                        <div class="table-contant-wrp">
                          <TopSellingArticles />
                        </div>
                    </div>
                  </div>
              </div>
          </div>
          </div>
      </div>
      <!-- <div class="dashboard-bottom-section dashboard-top-section col-12">
        <div class="dts-row-bottom">
          <div class="dts-box-outer">
            <CustomerNeedRestock />
          </div>
          <div class="dts-box-outer">
            <ChurnPotentialCutomers />
          </div>
        </div>
        !-- <div class="dbs-row">
          <div class="dbs-box-outer">
            <ArticlesCategory></ArticlesCategory>
          </div>
          <div class="dbs-box-outer">
            <ArticlesTranding
              :dateRange="dateFilterParams"
              :articles="articles"
              dataParam="sold_items"
              sortParam="-sold_items"
              limit="3"
            >
            </ArticlesTranding>
          </div>
          <div class="dbs-box-outer">
            <ArticlesAudience :audiences="audiences"></ArticlesAudience>
          </div>
        </div> --
      </div> -->

      <!-- <div class="dashboard-bottom-section dashboard-top-section">
      <div class="dashboard-bottom-section dashboard-top-section">
        <div class="dts-row-bottom">
          <div class="dts-box-outer">
            <ReoccuringCustomersCard />
          </div>
          <div class="dts-box-outer">
            <MostValuableCustomersCard />
          </div>
          <div class="dts-box-outer">
            <TopTrendingArticles />
          </div>
        </div>
      </div> -->

      <!-- <div class="dashboard-bottom-section dashboard-top-section">
        <div class="dts-row-bottom">
          <div class="dts-box-outer">
            <ArticleRestockingCentralWarehouse />
          </div>
          <div class="dts-box-outer">
            <ArticleRestockingNationalWarehouse />
          </div>
          <div class="dts-box-outer">
            <TopSellingArticles />
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  LIST_ARTICLES,
  TRENDING_ARTICLES,
  LIST_AUDIENCE,
  GET_METRICS,
} from "@/Core/store/action-types";
import TopBar from "../Menu/TopBar.vue";
import MainMenu from "../Menu/MainMenu.vue";
import UpArrow from "../icons/upArrow.vue";
import DownArrow from "../icons/downArrow.vue";
import CustomerNeedRestock from "./components/CustomerNeedRestock.vue"
import ChurnPotentialCutomers from "./components/ChurnPotentialCutomers.vue"
import ArticleRestockingCentralWarehouse from "./components/ArticleRestockingCentralWarehouse.vue"
import ArticleRestockingNationalWarehouse from "./components/ArticleRestockingNationalWarehouse.vue"
import MostValuableCustomersCard from "./components/MostValuableCustomersCard.vue";
import ReoccuringCustomersCard from "./components/ReoccuringCustomersCard.vue";
import TopSellingArticles from "./components/TopSellingArticles.vue";
import TopTrendingArticles from "./components/TopTrendingArticles.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "Home",
  components: {
    TopBar,
    UpArrow,
    DownArrow,
    CustomerNeedRestock,
    ChurnPotentialCutomers,
    ArticleRestockingCentralWarehouse,
    ArticleRestockingNationalWarehouse,
    MostValuableCustomersCard,
    ReoccuringCustomersCard,
    TopSellingArticles,
    TopTrendingArticles,
    MainMenu,
    Loading,
  },
  data() {
    return {
      fullPage: true,
      isLoading: true,
      generalMetrics: {
        totalOrders: 0,
        totalMembers: 0,
        totalProducts: 0,
        totalArticles: 0,
        get_avg_order_value: {},
        get_avg_clv_value: {},
        get_median_clv_value: {},
      },
      // convertedValues: {
      //   avg_order_value: 0,
      //   avg_clv_value: 0,
      //   median_clv_value: 0,
      // },
      // predefindedDates: {
      //   Yesterday: [window.moment().subtract(1, "days"), window.moment()],
      //   "Last 7 Days": [window.moment().subtract(6, "days"), window.moment()],
      //   "Last 30 Days": [window.moment().subtract(29, "days"), window.moment()],
      //   "This Month": [
      //     window.moment().startOf("month"),
      //     window.moment().endOf("month"),
      //   ],
      //   "This Year": [
      //     window.moment().startOf("year"),
      //     window.moment().endOf("year"),
      //   ],
      // },
      dateFilterParams: {},
      articles: {},
      audiences: {},
    };
  },
  computed: {
    ...mapGetters("org", ["default_clv_year", "default_currency"]),
  },
  methods: {
    ...mapActions("article", [LIST_ARTICLES, TRENDING_ARTICLES]),
    ...mapActions("metrics", [GET_METRICS]),
    ...mapActions("audience", [LIST_AUDIENCE]),
    // onDateChange(start, end) {
    //   this.dateFilterParams = {
    //     date_after: start.format(DATE_FORMAT),
    //     date_before: end.format(DATE_FORMAT),
    //   };
    //   this.initMetrics();
    //   // this.getArticles();
    //   this.getAudiences();
    // },
    async initMetrics() {
      // let params = this.dateFilterParams;
      let params = {
        currency: this.default_currency,
      };
      // params.is_active = 1;
      await this[GET_METRICS](params).then((res) => {
        this.generalMetrics = res;
        // this.convertCurrency();
      });
    },
    async getArticles() {
      // let params = this.dateFilterParams;
      let params = {};
      params.order = "-sold_items";
      params.limit = 3;
      params.direction = "up";
      await this[LIST_ARTICLES]({ params: params, persist: true }).then(
        (res) => {
          this.articles = res.results;
        }
      );
    },
    async getAudiences() {
      // let params = this.dateFilterParams;
      let params = {};
      params.limit = 3;
      await this[LIST_AUDIENCE]({ params: params, persist: true }).then(
        (res) => {
          this.audiences = res;
        }
      );
    },
    // async convertCurrency() {
    //   this.convertedValues.avg_clv_value = await currencyConverter(
    //     this.generalMetrics.get_avg_clv_value?.currency,
    //     this.default_currency,
    //     this.generalMetrics.get_avg_clv_value?.avg_clv_value
    //   );
    //   this.convertedValues.median_clv_value = await currencyConverter(
    //     this.generalMetrics.get_median_clv_value?.currency,
    //     this.default_currency,
    //     this.generalMetrics.get_median_clv_value?.median_clv_value
    //   );
    //   this.convertedValues.avg_order_value = await currencyConverter(
    //     this.generalMetrics.get_avg_order_value?.currency,
    //     this.default_currency,
    //     this.generalMetrics.get_avg_order_value?.avg_receipt_value
    //   );
    // },
    last_month_last_date() {
      var d = new Date(); // current date
      d.setDate(1); // going to 1st of the month
      d.setHours(-1); // going to last hour before this date even started.
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    },
    one_month_ago_date() {
      var d = new Date(new Date().setDate(new Date().getDate() - 30));
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    },
  },
  created() {
    // this.initDateRange = [
    //   this.predefindedDates["Last 7 Days"][0],
    //   this.predefindedDates["Last 7 Days"][1],
    // ];
    // this.onDateChange(this.initDateRange[0], this.initDateRange[1]);
    // this.$root.$refs.Dashboard = this;
    // this.getArticles();
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
    this.initMetrics();
    // this.initValuableCustomer();
    // this.getAudiences();
  },
};
</script>
<style>
.second-row-tab{
  box-shadow: 10px 5px 5px #bfbfc1 !important;
}
</style>
<style scoped>

/* Ensure the parent container uses flexbox to align children horizontally */
.dashboard-bottom-section .dts-row-bottom {
  display: flex;
  justify-content: space-between; /* Distributes space between the components */
  align-items: stretch; /* Aligns items vertically */
  flex-wrap: wrap; /* Allows items to wrap onto the next line if not enough space */
}

/* Adjust the flex properties of each box to take equal width */
.dashboard-bottom-section .dts-row-bottom .dts-box-outer {
  flex: 1 1 33%; /* Grow and shrink equally, basis at 33% */
  padding: 10px; /* Optional: Adds spacing around each box */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

/* Optional: Add media query for responsiveness on smaller screens */
@media (max-width: 768px) {
  .dashboard-bottom-section .dts-row-bottom .dts-box-outer {
    flex: 1 1 100%; /* Each box takes full width on small screens */
  }
}

.dashboard-bottom-section {
  padding-bottom: 0px;
}
.dashboard-bottom-section .dts-row .dts-box-outer {
  padding: 0 10px;
  width: 33.33%;
  flex: 0 0 33.33%;
}
.dashboard-bottom-section .dts-row .dts-box-outer .dts-box {
  height: 100%;
}
.dts-row .dts-box-outer .dts-box{
  box-shadow: 10px 5px 5px #bfbfc1 !important;
}
.dashboard-bottom-section .dts-row .dts-box-outer .dts-box h6 {
  font-size: 22px !important;
}
.bottom__title span {
  font-size: 15px !important;
  margin-bottom: 0 !important;
}

.bottom__title span:nth-child(2) {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
select#adminfilter {
  border-radius: 3px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #cacbcd;
  border-radius: 4px;
  font-weight: 300;
}

select#adminfilter:hover {
  border: 1px solid #000;
}
.view-more-btn {
  color: #5f66fa !important;
  font-weight: 500;
  cursor: pointer;
}
.customer-list-box {
  height: 100%;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.campaign-box {
  background-color: #5e66fa !important;
  color: #f4be55 !important;
}
.first-row-box{
  display: flex;
  justify-content: space-between;
}
.customer-box {
  background: #30d2dc !important;
}
.article-box {
  background-color: #f4be55 !important;
}
.campaign-box h6,
.campaign-box span,
.customer-box h6,
.customer-box span,
.article-box h6,
.article-box span {
  color: #fff !important;
}
.box-icon {
  margin-top: 20%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
 