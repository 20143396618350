import { API_URL, authHeader } from "@/Core/helpers/utils";
import axios from "axios";

const RESOURCE_URL = `${API_URL}/api/v1/members/`;

export default {
  get(token, params) {
    let url = `${RESOURCE_URL}?`;

    if (params && params.prefs) {
      params.prefs.forEach((id) => {
        url += `&pref=${id}`;
      });
      delete params.prefs;
    }

    return axios.get(url, {
      params: params,
      headers: authHeader(token),
    });
  },
  update(token, obj) {
    let url = `${RESOURCE_URL}${obj.id}/`;
    return axios.patch(url, obj, {
      headers: authHeader(token),
    });
  },
  get_recent(token) {
    let url = `${RESOURCE_URL}recent_member/`;
    return axios.get(url, {
      headers: authHeader(token),
    });
  },

  create(token, obj) {
    return axios.post(RESOURCE_URL, obj, {
      headers: authHeader(token),
    });
  },
  delete(token, objId) {
    let url = `${RESOURCE_URL}${objId}/`;
    return axios.delete(url, {
      headers: authHeader(token),
    });
  },
  get_receipts(token, params, objId) {
    let url = `${RESOURCE_URL}${objId}/receipts/`;
    return axios.get(url, {
      params: params,
      headers: authHeader(token),
    });
  },
  get_member_purchases(token, params, externalId) {
    let url = `${API_URL}/api/v1/orders-list/?customer_id=${externalId}`; // Use external_id
    return axios.get(url, {
      params: params,
      headers: authHeader(token),
    });
  },
  metrics(token, params) {
    return axios.get(RESOURCE_URL, {
      params,
      headers: authHeader(token),
    });
  },
  get_member_detail(token, params) {
    let url = `${RESOURCE_URL}${params.customerId}`;
    return axios.get(url, {
      params,
      headers: authHeader(token),
    });
  },
  get_gdpr_data(token, objId) {
    let url = `${API_URL}/api/v1/get_gdpr_data/${objId}`;
    return axios.get(url, {
      headers: authHeader(token),
    });
  },
  get_highest_ordering_customers(token, params) {
    let url = `${API_URL}/api/v1/highest_ordering_customers`;
    return axios.get(url, {
      params,
      headers: authHeader(token),
    });
  },
  get_most_reoccuring_customers(token, params) {
    let url = `${API_URL}/api/v1/most_reoccuring_customers`;
    return axios.get(url, {
      params,
      headers: authHeader(token),
    });
  },
  get_avg_clv_value(token,params) {
    let url = `${API_URL}/api/v1/get_avg_clv_value`;
    return axios.get(url, {
      headers: authHeader(token),params
    });
  },
  get_median_clv_value(token,params) {
    let url = `${API_URL}/api/v1/get_median_clv_value`;
    return axios.get(url, {
      headers: authHeader(token),params
    });
  },
  customer_file_upload(token, file) {
    let params = new FormData();
    params.append("file", file);
    return axios.post(`${API_URL}/api/v1/upload_customer_file/`, params, {
      headers: authHeader(token),
    });
  },
  article_file_upload(token, file) {
    let params = new FormData();
    params.append("file", file);
    return axios.post(`${API_URL}/api/v1/upload_article_file/`, params, {
      headers: authHeader(token),
    });
  },
  inventory_file_upload(token, file) {
    let params = new FormData();
    params.append("file", file);
    return axios.post(`${API_URL}/api/v1/upload_inventory_file/`, params, {
      headers: authHeader(token),
    });
  },
  get_customer_restock(token, params) {
    let url = `${API_URL}/api/v1/customers_needing_restock/`;
    return axios.get(url, {
      params,
      headers: authHeader(token),
    });
  },
  get_churn_potential_customers(token, params) {
    let url = `${API_URL}/api/v1/churn_potential_customers/`;
    return axios.get(url, {
      params,
      headers: authHeader(token),
    });
  },
};
