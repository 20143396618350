


<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M14.5333 12.1834L16.6667 10.0501L14.5333 7.91675" stroke="#565DF2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.13336 10.05H16.6084" stroke="#565DF2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.80003 16.6666C6.1167 16.6666 3.13336 14.1666 3.13336 9.99992C3.13336 5.83325 6.1167 3.33325 9.80003 3.33325" stroke="#565DF2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
  name: "LogOutIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>