<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <MainMenu />
    <div class="dashboard-bottom-section dashboard-top-section col-12">
      <div class="dts-row-bottom">
        <div class="dts-box-outer">
          <ArticlesLowStockNationalWarehouse />
        </div>
        <div class="dts-box-outer">
          <IntransitArticlesNationalWarehouse />
        </div>
        <div class="dts-box-outer">
          <ArticlesLowStockCentralWarehouse/>
        </div>
      </div>
    </div>

    <div class="dashboard-bottom-section dashboard-top-section">
      <div class="dts-row-bottom">
        <div class="dts-box-outer">
          <TopInventoryTurnOverNationalWarehouse />
        </div>
        <div class="dts-box-outer">
          <LowestInventoryTurnOverNationalWarehouse />
        </div>
        <div class="dts-box-outer">
          <AverageInventoryTurnOver />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  LIST_ARTICLES,
  TRENDING_ARTICLES,
  LIST_AUDIENCE,
  GET_METRICS,
} from "@/Core/store/action-types";
import MainMenu from "../Menu/MainMenu.vue";
import ArticlesLowStockNationalWarehouse from "./components/ArticlesLowStockNationalWarehouse.vue";
import ArticlesLowStockCentralWarehouse from "./components/ArticlesLowStockCentralWarehouse.vue";
import IntransitArticlesNationalWarehouse from "./components/IntransitArticlesNationalWarehouse.vue";
import TopInventoryTurnOverNationalWarehouse from "./components/TopInventoryTurnOverNationalWarehouse.vue";
import LowestInventoryTurnOverNationalWarehouse from "./components/LowestInventoryTurnOverNationalWarehouse.vue";
import AverageInventoryTurnOver from "./components/AverageInventoryTurnOver.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "Home",
  components: {
    ArticlesLowStockNationalWarehouse,
    ArticlesLowStockCentralWarehouse,
    IntransitArticlesNationalWarehouse,
    TopInventoryTurnOverNationalWarehouse,
    LowestInventoryTurnOverNationalWarehouse,
    AverageInventoryTurnOver,
    MainMenu,
    Loading,
  },
  data() {
    return {
      fullPage: true,
      isLoading: true,
      generalMetrics: {
        totalOrders: 0,
        totalMembers: 0,
        totalProducts: 0,
        totalArticles: 0,
        get_avg_order_value: {},
        get_avg_clv_value: {},
        get_median_clv_value: {},
      },
      
      dateFilterParams: {},
      articles: {},
      audiences: {},
    };
  },
  computed: {
    ...mapGetters("org", ["default_clv_year", "default_currency"]),
  },
  methods: {
    ...mapActions("article", [LIST_ARTICLES, TRENDING_ARTICLES]),
    ...mapActions("metrics", [GET_METRICS]),
    ...mapActions("audience", [LIST_AUDIENCE]),
    // onDateChange(start, end) {
    //   this.dateFilterParams = {
    //     date_after: start.format(DATE_FORMAT),
    //     date_before: end.format(DATE_FORMAT),
    //   };
    //   this.initMetrics();
    //   // this.getArticles();
    //   this.getAudiences();
    // },
    async initMetrics() {
      // let params = this.dateFilterParams;
      let params = {
        currency: this.default_currency,
      };
      // params.is_active = 1;
      await this[GET_METRICS](params).then((res) => {
        this.generalMetrics = res;
        // this.convertCurrency();
      });
    },
    async getArticles() {
      // let params = this.dateFilterParams;
      let params = {};
      params.order = "-sold_items";
      params.limit = 3;
      params.direction = "up";
      await this[LIST_ARTICLES]({ params: params, persist: true }).then(
        (res) => {
          this.articles = res.results;
        }
      );
    },
    async getAudiences() {
      // let params = this.dateFilterParams;
      let params = {};
      params.limit = 3;
      await this[LIST_AUDIENCE]({ params: params, persist: true }).then(
        (res) => {
          this.audiences = res;
        }
      );
    },
    // async convertCurrency() {
    //   this.convertedValues.avg_clv_value = await currencyConverter(
    //     this.generalMetrics.get_avg_clv_value?.currency,
    //     this.default_currency,
    //     this.generalMetrics.get_avg_clv_value?.avg_clv_value
    //   );
    //   this.convertedValues.median_clv_value = await currencyConverter(
    //     this.generalMetrics.get_median_clv_value?.currency,
    //     this.default_currency,
    //     this.generalMetrics.get_median_clv_value?.median_clv_value
    //   );
    //   this.convertedValues.avg_order_value = await currencyConverter(
    //     this.generalMetrics.get_avg_order_value?.currency,
    //     this.default_currency,
    //     this.generalMetrics.get_avg_order_value?.avg_receipt_value
    //   );
    // },
    last_month_last_date() {
      var d = new Date(); // current date
      d.setDate(1); // going to 1st of the month
      d.setHours(-1); // going to last hour before this date even started.
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    },
    one_month_ago_date() {
      var d = new Date(new Date().setDate(new Date().getDate() - 30));
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    },
  },
  created() {
    // this.initDateRange = [
    //   this.predefindedDates["Last 7 Days"][0],
    //   this.predefindedDates["Last 7 Days"][1],
    // ];
    // this.onDateChange(this.initDateRange[0], this.initDateRange[1]);
    // this.$root.$refs.Dashboard = this;
    // this.getArticles();
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
    this.initMetrics();
    // this.initValuableCustomer();
    // this.getAudiences();
  },
};
</script>
<style>
.second-row-tab{
  box-shadow: 10px 5px 5px #bfbfc1 !important;
}
</style>
<style scoped>

/* Ensure the parent container uses flexbox to align children horizontally */
.dashboard-bottom-section .dts-row-bottom {
  display: flex;
  justify-content: space-between; /* Distributes space between the components */
  align-items: stretch; /* Aligns items vertically */
  flex-wrap: wrap; /* Allows items to wrap onto the next line if not enough space */
}

/* Adjust the flex properties of each box to take equal width */
.dashboard-bottom-section .dts-row-bottom .dts-box-outer {
  flex: 1 1 33%; /* Grow and shrink equally, basis at 33% */
  padding: 10px; /* Optional: Adds spacing around each box */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

/* Optional: Add media query for responsiveness on smaller screens */
@media (max-width: 768px) {
  .dashboard-bottom-section .dts-row-bottom .dts-box-outer {
    flex: 1 1 100%; /* Each box takes full width on small screens */
  }
}

.dashboard-bottom-section {
  padding-bottom: 0px;
}
.dashboard-bottom-section .dts-row .dts-box-outer {
  padding: 0 10px;
  width: 33.33%;
  flex: 0 0 33.33%;
}
.dashboard-bottom-section .dts-row .dts-box-outer .dts-box {
  height: 100%;
}
.dts-row .dts-box-outer .dts-box{
  box-shadow: 10px 5px 5px #bfbfc1 !important;
}
.dashboard-bottom-section .dts-row .dts-box-outer .dts-box h6 {
  font-size: 22px !important;
}
.bottom__title span {
  font-size: 15px !important;
  margin-bottom: 0 !important;
}

.bottom__title span:nth-child(2) {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
select#adminfilter {
  border-radius: 3px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #cacbcd;
  border-radius: 4px;
  font-weight: 300;
}

select#adminfilter:hover {
  border: 1px solid #000;
}
.view-more-btn {
  color: #5f66fa !important;
  font-weight: 500;
  cursor: pointer;
}
.customer-list-box {
  height: 100%;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.campaign-box {
  background-color: #5e66fa !important;
  color: #f4be55 !important;
}
.first-row-box{
  display: flex;
  justify-content: space-between;
}
.customer-box {
  background: #30d2dc !important;
}
.article-box {
  background-color: #f4be55 !important;
}
.campaign-box h6,
.campaign-box span,
.customer-box h6,
.customer-box span,
.article-box h6,
.article-box span {
  color: #fff !important;
}
.box-icon {
  margin-top: 20%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
 