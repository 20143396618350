<template>
  <div class="contant-box-main customer-list-box">
    <div class="h-100">
      <div class="contant-header">
        <h6>
          <img 
            src="@/../public/assets/images/star-icon.png" 
            alt="" 
          /> Customer Restock Soon
        </h6>
        <div>
         <!-- Replaced dropdown with time period display -->
          <span class="date-display" v-if="restock_period_start && restock_period_end">
            {{ restock_period_start }} - {{ restock_period_end }}
          </span>
        </div>
      </div>
      <div class="contant-details h-100">
        <div class="overflow-auto position-relative" :class="loadindShow?'h-100':''">
          <div class="loader-main" v-if="loadindShow">
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
           </div>
          <table class="table" id="my-table">
            <thead>
              <tr>
                <th>Customer Id</th>
                <th>Customer Name</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="customer in customer_restock_soon" :key="customer.id">
                <td class="font-light-text" width="20%">
                  {{ customer.external_id }}
                </td>
                <td width="60%">{{ customer.company_name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="contant-footer d-flex justify-content-end">
      <a
        role="button"
        data-toggle="modal"
        data-target="#customerRestockSoonModel"
        class="view-more-btn"
        @click="initCustomerRestockSoon(true)"
      >
        View All
        <img src="@/../public/assets/images/right-sky.png" alt=""
      /></a>
    </div>
  </div>
  <div
    class="modal fade"
    id="customerRestockSoonModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="customerRestockSoonModelTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="customerRestockSoonModelTitle">
            Customer Restock Soon
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-12">
                  <div class="contant-header">
                    <h6>
                      <img
                        src="@/../public/assets/images/star-icon.png"
                        alt=""
                      />
                      Customer Restock Soon
                    </h6>
                    <button
                      type="button "
                      class="save-btn"
                      @click.prevent="generateCSV(customer_restock_soon)"
                    >
                      Export List
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-lg-12">
                  <div class="model_table">
                    <div class="table-wrp overflow-auto position-relative">
                      <div class="loader-main" v-if="loadindShow">
                          <div
                            class="spinner-border"
                            role="status"
                            style="width: 36px; height: 36px; color: #5e66fa"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      <table class="table my-table-wrp table-sm" id="my-table">
                        <thead>
                          <tr>
                            <th width="5%" class="pl-0">
                              <div class="checkbox-list">
                                <label class="checkbox">
                                  <input
                                    @change="AddRemoveAllArticles()"
                                    class="checkbox-input"
                                    type="checkbox"
                                    :checked="isAllSelected"
                                  />
                                  <span class="checkbox-checkmark-box">
                                    <span class="checkbox-checkmark"></span>
                                  </span>
                                </label>
                              </div>
                            </th>
                            <th>Customer Id</th>
                            <th>Customer Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="customer in customer_restock_soon? customer_restock_soon.slice(
                                  10 * (page_model - 1),
                                  page_model * 10
                                )
                              : []"
                            :key="customer.id"
                            :class="
                              checkedFilter(customer.id)
                                ? 'active-row selected'
                                : ''
                            "
                          >
                            <td width="5%">
                              <div class="checkbox-list">
                                <label class="checkbox">
                                  <input
                                    @change="
                                      AddRemoveCustomer(customer.id)
                                    "
                                    class="checkbox-input"
                                    type="checkbox"
                                    :checked="
                                      checkedFilter(customer.id)
                                    "
                                  />
                                  <span class="checkbox-checkmark-box">
                                    <span class="checkbox-checkmark"></span>
                                  </span>
                                </label>
                              </div>
                            </td>
                            <td class="font-light-text" width="20%">
                              {{ customer.external_id }}
                            </td>
                            <td width="55%">{{ customer.company_name }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      :page="page_model"
                      :totalPages="
                        customer_restock_soon
                          ? Array(Math.ceil(customer_restock_soon.length / 10))
                              .fill(0)
                              .map((e, i) => i + 1)
                          : []
                      "
                      :name="'Customers'"
                      :count="
                        customer_restock_soon ? customer_restock_soon.length : 0
                      "
                      :incrementpage="incrementpage_model"
                      :decrementpage="decrementpage_model"
                      :setpage="setpage_model"
                      :perpage="10"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <div class="form-btns">
            <button
              type="button"
              class="cancle-btn"
              @click="oncancel"
              data-dismiss="modal"
              aria-label="Close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import {GET_CUSTOMER_RESTOCK_SOON} from "@/Core/store/action-types";
import Pagination from "../../Core/Pagination.vue";

export default {
  name: "CustomerRestockSoon",
  components: {
    Pagination,
  },
  data() {
    return {
      customer_restock_soon: [],
      all_customer_restock_soon: [],
      loadindShow:false,
      restock_period_start: '',
      restock_period_end: '',
    };
  },
  methods: {
    ...mapActions("customer", [GET_CUSTOMER_RESTOCK_SOON]),
    initCustomerRestockSoon(isAll = false) {
      this.loadindShow = true
      let params = {
        duration:  
          this.customerTime == "month"
            ? 30
            : this.customerTime == "week"
            ? 7
            : this.customerTime == "quater"
            ? 90
            : this.customerTime == "half a year"
            ? 180
            : 365,
        all: isAll ? isAll : null,  
      }
      this[GET_CUSTOMER_RESTOCK_SOON](params)
      .then((result)=>{
        if(isAll) this.all_customer_restock_soon = result.customers;
        else this.customer_restock_soon = result.customers;
        this.restock_period_start = result.restock_period_start;
        this.restock_period_end = result.restock_period_end;
      })
      .catch(()=>{
        this.all_customer_restock_soon = [];
        this.restock_period_start = '';
        this.restock_period_end = '';
      })
      .finally(()=>this.loadindShow=false)
    },
    generateCSV(customers) {
      let arrData = [
        {
          customers: customers,
        },
      ];
      window.$(`#exportCsvModel`).modal("hide");
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0].customers[0] || {}).join(","),
        ...arrData[0].customers.map((item) => Object.values(item).join(",")),
      ].join("\n");
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute(
        "download",
        `customer-restock-soon-${this.customerTime}.csv`
      );
      link.click();
    },
    onTimeChange() {
      this.initCustomerRestockSoon(false);
    },
    incrementpage_model() {
      this.page_model = this.page_model + 1;
    },
    // go to previous page in customer table in model
    decrementpage_model() {
      this.page_model = this.page_model - 1;
    },
    // go to perticular page in customer table in model
    setpage_model(page) {
      this.page_model = page;
    },
  },
  watch:{
    pref_type(value){
      console.log("ff",value)
      if(this.choosen_customers.length>0 && (value =="" || !value))this.pref_type_error = "please select customers preference type";
      else this.pref_type_error=''
    },
    choosen_customers(value){
      if(value.length == 0) {
        this.pref_type_error='',
        this.errMsg = "";
        }
    },
    audienceName(value){
      if (!value) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } 
      else if (value.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      }
      else {
        this.nameError = false;
        this.errMsg = "";
      }
    }
  },
  mounted() {
    this.initCustomerRestockSoon(false);
  },
};
</script>
<style scoped>
select#adminfilter {
  border-radius: 3px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #cacbcd;
  border-radius: 4px;
  font-weight: 300;
}

select#adminfilter:hover {
  border: 1px solid #000;
}
.customer-list-box {
  height: 100%;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.view-more-btn {
  color: #5f66fa !important;
  font-weight: 500;
}
.date-display {
  font-size: 14px; /* Adjust the font size as needed */
  color: #5e66fa; /* Example color, adjust as needed */
}
</style>