<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <path d="M7.5 2.49992C7.24583 2.49992 7.0125 2.35409 6.89583 2.12909L6.59583 1.52492C6.40417 1.14575 5.90417 0.833252 5.47917 0.833252H4.525C4.09583 0.833252 3.59583 1.14575 3.40417 1.52492L3.10417 2.12909C2.9875 2.35409 2.75417 2.49992 2.5 2.49992C1.59583 2.49992 0.879165 3.26242 0.937499 4.16242L1.15417 7.60409C1.20417 8.46242 1.66667 9.16659 2.81667 9.16659H7.18333C8.33333 9.16659 8.79167 8.46242 8.84583 7.60409L9.0625 4.16242C9.12083 3.26242 8.40417 2.49992 7.5 2.49992ZM4.375 3.02075H5.625C5.79583 3.02075 5.9375 3.16242 5.9375 3.33325C5.9375 3.50409 5.79583 3.64575 5.625 3.64575H4.375C4.20417 3.64575 4.0625 3.50409 4.0625 3.33325C4.0625 3.16242 4.20417 3.02075 4.375 3.02075ZM5 7.54992C4.225 7.54992 3.59167 6.92075 3.59167 6.14159C3.59167 5.36242 4.22083 4.73325 5 4.73325C5.77917 4.73325 6.40833 5.36242 6.40833 6.14159C6.40833 6.92075 5.775 7.54992 5 7.54992Z" fill="white"/>
    </svg>
</template>

<script>
export default {
  name: "CameraIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>