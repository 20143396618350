<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M10 16.6667V3.33337M10 3.33337L5 8.33337M10 3.33337L15 8.33337" stroke="#12B76A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 16.6667V3.33337M10 3.33337L5 8.33337M10 3.33337L15 8.33337" stroke="#1A2B3A" stroke-opacity="0.2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
  name: "UpArrow",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>