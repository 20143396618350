<template>
  <!-- <div id="header" v-if="userProfile.email"> -->
  <div
    id="header"
    v-if="userProfile.email && !this.currentUrl.includes('/changepassword')"
  >
    <div v-if="currentUrl === '/dashboard'" class="dashboard-header">
      <h2>Recommendations Dashboard <span>Home</span></h2>
      <!-- <div class="input-group input-daterange ml-auto">
        <label>Date range</label
        ><input
          id="icon-date"
          type="text"
          ref="dashboardDate"
          class="form-control daterange"
          readonly
          name="daterange"
          :value="selectedDateRange"
        />
      </div> -->
    </div>
    <div v-else-if="currentUrl === '/'" class="dashboard-header">
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <h2>
        Profile
        <span><router-link to="/dashboard">Home</router-link>Profile</span>
      </h2>
    </div>
    <div
      v-else-if="currentUrl === '/Cart/Recommendations'"
      class="dashboard-header"
    >
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <h2>
        Article Recommendations
        <span
          ><router-link to="/dashboard">Home</router-link>
          <router-link to="/Cart">Cart</router-link>{{
            author.home.map((id) => getProdById(id).name).toString()
          }}</span
        >
      </h2>
    </div>
    <div v-else-if="currentUrl === '/Cart'" class="dashboard-header">
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <h2>
        Most commonly connected articles
        <span><router-link to="/dashboard">Home</router-link>Cart</span>
      </h2>
    </div>
    <div v-else-if="currentUrl === '/Customers'" class="dashboard-header">
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <h2>
        Customers
        <span><router-link to="/dashboard">Home</router-link>Customers</span>
      </h2>
    </div>
    <div v-else-if="currentUrl === '/Users'" class="dashboard-header">
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <h2>
        Users
        <span><router-link to="/dashboard">Home</router-link>Users</span>
      </h2>
    </div>
    <div v-else-if="currentUrl.includes('/Users/')" class="dashboard-header">
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <h2>
        {{ author.home }}
        <span
          ><router-link to="/dashboard">Home</router-link>
          <router-link to="/Users">Users</router-link>> {{ author.home }}</span
        >
      </h2>
    </div>
    <div v-else-if="currentUrl === '/Audience'" class="dashboard-header">
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <h2>
        Audience
        <span><router-link to="/dashboard">Home</router-link>Audience</span>
      </h2>
    </div>
    <div v-else-if="currentUrl === '/Email'" class="dashboard-header">
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <h2>
        Email
        <span><router-link to="/Email">Home</router-link>Email</span>
      </h2>
    </div>
    <div v-else-if="currentUrl === '/Companies'" class="dashboard-header">
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <h2>
        Companies
        <span><router-link to="/dashboard">Home</router-link>Companies</span>
      </h2>
    </div>
    <div v-else-if="currentUrl === '/Admins'" class="dashboard-header">
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <h2>
        Admins
        <span><router-link to="/dashboard">Home</router-link>Admins</span>
      </h2>
    </div>
    <div v-else-if="currentUrl === '/AllAdmins'" class="dashboard-header">
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <h2>
        All Admins
        <span><router-link to="/dashboard">Home</router-link>All Admins</span>
      </h2>
    </div>
    <div v-else-if="currentUrl === '/analytics'" class="dashboard-header">
      <h2>
        Analytics
        <span><router-link to="/dashboard">Home</router-link>Analytics</span>
      </h2>
    </div>
    <div
      v-else-if="currentUrl.includes('/Customers/')"
      class="dashboard-header"
    >
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <h2>
        {{ author.home.id }} {{ author.home.name }}
        <span
          ><router-link to="/dashboard">Home</router-link>
          <router-link to="/Customers">Customers</router-link></span
        >
      </h2>
      <!-- <div class="warning"> Account has been deleted.Extract GDPR info to save the analytics data.</div> -->
    </div>
    <div v-else-if="currentUrl.includes('/Audience/')" class="dashboard-header">
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <h2>
        {{ author.home }}
        <span
          ><router-link to="/dashboard">Home</router-link>
          <router-link to="/Audience">Audience</router-link>
          {{ author.home }}</span
        >
      </h2>
    </div>
    <div v-else-if="currentUrl.includes('/Email/')" class="dashboard-header">
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <h2>
        {{ author.home }}
        <span>
          <router-link to="/dashboard">Home</router-link>
          <router-link to="/Email">Email</router-link>
          {{ author.home }}</span>
      </h2>
    </div>
    <div v-else-if="currentUrl.includes('/Inventory')" class="dashboard-header">
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <h2>
        Inventory
        <span>
          <router-link to="/dashboard">Home</router-link>
          Inventory
        </span>
      </h2>
    </div>
    <div v-else-if="currentUrl.includes('/Companies/')" class="dashboard-header">
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <h2>
        {{ author.home }}
        <span
          ><router-link to="/dashboard">Home</router-link>
          <router-link to="/Companies">Companies</router-link>
          {{ author.home }}</span
        >
      </h2>
    </div>
    <div v-else>
      <button class="toggle-btn" @click="$router.go(-1)"></button>
      <div class="title-details">
        <h2>{{ title }}</h2>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#"></a></li>
            <!-- <li class="breadcrumb-item"><a href="#">{{auth.dashboard}}</a></li> -->

            <!-- <li class="breadcrumb-item active" aria-current="page">{{author.cart}}</li> -->
            <li class="breadcrumb-item active" aria-current="page"></li>
            <!-- <li class="breadcrumb-item active" aria-current="page">Library</li> -->
          </ol>
        </nav>
      </div>
    </div>
    <div class="header-logo" @click="$router.push('/dashboard')">
      <img
        src="../../public/assets/images/login_logo.png"
        height="40"
        alt="logo"
        class="auth-logo"
      />
    </div>
    <div class="overview-box" v-if="!hasAdminPageAccess(userProfile)">
      <h2>In {{new Date().toLocaleString('en-US', { month: 'long' })}}</h2>
      <div class="overview-details">
        <p>Total Orders : <span>{{receiptStats?.current_month_receipts_count || 0}}</span> </p>
        <!-- <p>Identified Customer Receipts: <span>{{receiptStats?.identified_customer_receipt_count || 0}} <em>({{receiptStats?.diffrence?.toFixed(0) || 0}}%)</em></span></p> -->
      </div>
    </div>
    <!--<div v-if="currentUrl == '/Cart'" id="product-tabs">
            <div class="toolbar-holder p-2">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb p-2">
                        <li class="breadcrumb-item">Sort by</li>
                    </ol>
                </nav>
                <div class=" select-holder ">
                    <select class="nav-dropdown">
                        <option>Most Common Combinations</option>
                        <option>Most Common Combinations</option>
                        <option>Most Common Combinations</option>
                        <option>Most Common Combinations</option>
                    </select>
                </div>
            </div>
        </div>-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { LOGOUT,GET_RECEIPTS_STATS } from "@/Core/store/action-types";
import { UserRoleMixin } from "@/Core/mixins/UserRoleMixin";
import { DATE_FORMAT } from "../Core/helpers/utils";

export default {
  name: "TopBar",
  computed: {
    ...mapGetters("user", ["userProfile"]),
    ...mapGetters("product", ["getProdById"]),
  },
  mixins: [UserRoleMixin],
  methods: {
    ...mapActions("user", [LOGOUT]),
    ...mapActions("receipt",[GET_RECEIPTS_STATS]),
    initReceiptStats(){
      this[GET_RECEIPTS_STATS](this.userProfile?.organization?.id).then((res)=>{
        this.receiptStats=res
      })
      .catch((err)=>{
        console.log(err)
        this.receiptStats=null
      })
    },
    signOut() {
      this[LOGOUT](this.$ability);
      this.$router.push("/login");
    },
    datechange(startDate, endDate) {
      this.$parent?.onDateChange(startDate, endDate);
      this.selectedDateRange =
        startDate.format(DATE_FORMAT) + " - " + endDate.format(DATE_FORMAT);
    },
  },
  props: {
    author: {
      required: false,
      type: Object,
    },
    title: String,
  },
  data: function () {
    return {
      currentUrl: "",
      receiptStats:null
      // predefindedDashboardDates: {
      //   Yesterday: [window.moment().subtract(1, "days"), window.moment()],
      //   "Last 7 Days": [window.moment().subtract(6, "days"), window.moment()],
      //   "Last 30 Days": [window.moment().subtract(29, "days"), window.moment()],
      //   "This Month": [
      //     window.moment().startOf("month"),
      //     window.moment().endOf("month"),
      //   ],
      //   "This Year": [
      //     window.moment().startOf("year"),
      //     window.moment().endOf("year"),
      //   ],
      // },
      // selectedDateRange: "",
    };
  },

  created() {
    this.currentUrl = this.$route.path;
  },
  mounted() {
    this.datepicker = window.$(this.$refs.dashboardDate);
    let _this = this;
    if(this.userProfile?.organization?.id) this.initReceiptStats()
    this.datepicker.daterangepicker(
      {
        autoUpdateInput: false,
        ranges: _this.predefindedDashboardDates,
        locale: {
          format: "DD/MM/YYYY",
        },
      },
      this.datechange
      // function (startDate, endDate) {

      //   _this.selectedDateRange =
      //     startDate.format(DATE_FORMAT) + " - " + endDate.format(DATE_FORMAT);
      // }
    );
    //  this.$root.$refs.Dashboard.onDateChange);
    // this.selectedDateRange =
    //   this.predefindedDashboardDates["Last 7 Days"][0].format(DATE_FORMAT) +
    //   " - " +
    //   this.predefindedDashboardDates["Last 7 Days"][1].format(DATE_FORMAT);
  },
};
</script>

<style>
.head-logo {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.dashboard-header {
  z-index: 1;
  width: 100%;
  display: flex;
  position: relative;
}
.dashboard-header label {
  margin-right: 8px;
  color: gray;
}
.dashboard-header .input-group {
  width: 25%;
  justify-content: center;
  align-items: center;
}
.dashboard-header h2 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500px;
}
.dashboard-header h2 span {
  display: block;
  font-size: 14px;
  margin-top: 5px;
  color: gray;
  font-weight: 300;
}
#header h2 {
  font-size: 22px;
  line-height: 1;
  font-weight: 700;
  margin: 0 0 5px;
  color: #3f3f40;
  width: 40%;
}
.overview-box {
    /* padding: 5px; */
    margin-top: 0px;
    /* border: 1px solid #5e66fa; */
    /* border-radius: 3px; */
    background-color: #fff;
    min-width: 270px;
}

.overview-box p {
    margin: 0;
}

.overview-box p {
    display: flex;
}

.overview-details {
    display: flex;
    align-items: center;
    gap: 9px;
}
.overview-details p {
    display: block;
    font-size: 14px;
    margin-top: 5px;
    color: gray;
    font-weight: 300;
}
.overview-details p span {
    color: #5e66fa;
    font-weight: 600;
}
.overview-details p span  em{
    color: #8b8b8b;
    font-style:normal;
}
</style>
