<template>
  <div class="container-fluid flex-shrink-0 p-0 m-0">
    <MainMenu  v-if="!isInPublicRoute()" />
    <TopBar v-if="!isInPublicRoute()"/>
    <router-view />
  </div>
</template>

<script>
import TopBar from "@/Menu/TopBar.vue";
import MainMenu from "@/Menu/MainMenu.vue";
import { mapGetters } from "vuex";
// import router from "./Router";

export default {
  name: "App",
  components: {
    TopBar,
    MainMenu,
  },
  computed: {
    ...mapGetters("user", { userProfile: "userProfile" }),
  },
  methods:{
    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.VUE_APP_FACEBOOK_APP_ID, 
          cookie: false,
          version: "v17.0",
        });
      };
    },
    isInPublicRoute() {
      return this.$route.path.includes("/changepassword/");
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
  },
  async mounted(){
    await this.loadFacebookSDK(document, "script", "facebook-jssdk");
    await this.initFacebook();
  },
  created() {
    if (!this.userProfile.email) {
      this.$ability.update([]);
      // router.push("/login");
    }
    // console.log("routee", this.$route.path);
  },
};
</script>

<style>
</style>
