<template>
  <div class="contant-box-main">
    <div class="contant-header">
      <h6>
        <img
          src="./../../../public/assets/images/coolicon-icon.png"
          alt=""
        />Customer details
      </h6>
      <div class="delete-status-box">
        <!-- delete customer model start -->
        <CustomModel
          :modalId="deleteCustomerModelId"
          :item="customerDetails"
          :onDelete="deleteCustomer"
          :resourseName="'customer'"
          actionType="Delete"
        ></CustomModel>
        <!-- delete customer model end -->
        <div class="data-heading-wrp">
          <div class="data-heading-btn">
            <button @click="showConnectWarehouseDialog">Connect to Warehouse</button>
          </div>
        </div>
        <div class="status-box">
          <label :class="customerDetails?.is_active ? 'active-status m-0' : 'm-0'">
            <span>Active</span>
          </label>
          <label
            :class="customerDetails?.is_active ? 'm-0' : 'inactive-status m-0'"
          >
            <span>Inactive</span>
          </label>
        </div>
        <button
          title="Delete Customer"
          v-show="hasPermission"
          class="delete-profile"
          @click="showDeleteDialog"
        >
          <!-- @click="deleteCustomer(JSON.parse(JSON.stringify(customerDetails)))" -->
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.75 16.5H5.25C4.42157 16.5 3.75 15.8284 3.75 15V5.25H2.25V3.75H5.25V3C5.25 2.17157 5.92157 1.5 6.75 1.5H11.25C12.0784 1.5 12.75 2.17157 12.75 3V3.75H15.75V5.25H14.25V15C14.25 15.8284 13.5784 16.5 12.75 16.5ZM5.25 5.25V15H12.75V5.25H5.25ZM6.75 3V3.75H11.25V3H6.75Z"
              fill="#EC4424"
            />
          </svg>
        </button>
      </div>
    </div>

    <!-- Connect Warehouse modal Window -->
    <div class="modal fade" id="connectWarehouseModel" tabindex="-1" role="dialog" aria-labelledby="createDialogLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="createDialogLabel">
              Connect to Warehouse
            </h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <div>
                <label for="parent">Select National/Distribution Warehouse</label>
                <select class="form-select" name="parent" v-model="selectedWarehouse">
                  <option value="Select a warehouse" disabled selected>Select a warehouse</option>
                  <option v-for="warehouse in warehouses" :key="warehouse.id" :value="warehouse.id">
                    {{ warehouse.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="save-btn" @click="connectToWarehouse">Connect</button>
            <button type="button" class="cancle-btn" data-bs-dismiss="modal"> Cancel </button>
          </div>
        </div>
      </div>
    </div>

    <div class="profile-details position-relative">
      <div class="loader-main" v-if="loadindShow" >
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
        </div>
      <div class="profile-field_wrp">
        <div class="profile_field_data">
          <h6 class="profile-title">First Name</h6>
          <p class="profile-title">{{ customerDetails?.first_name || '-'}}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Last Name</h6>
          <p class="profile-title">{{ customerDetails?.last_name || '-'}}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Company Name</h6>
          <p class="profile-title">{{ customerDetails?.company_name || '-'}}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Email</h6>
          <p class="profile-title">{{ customerDetails?.email || '-'}}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Phone</h6>
          <p class="profile-title">{{ customerDetails?.phone  || '-'}}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Address</h6>
          <p class="profile-title">{{ customerDetails?.address  || '-' }}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Postal code</h6>
          <p class="profile-title">{{ customerDetails?.postal_id  || '-'}}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">City</h6>
          <p class="profile-title">{{ customerDetails?.city  || '-' }}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">State</h6>
          <p class="profile-title">{{ customerDetails?.state  || '-' }}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Country</h6>
          <p class="profile-title">{{ customerDetails?.country  || '-'}}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Internal ID</h6>
          <p class="profile-title">{{ customerDetails?.id  || '-' }}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">External ID</h6>
          <p class="profile-title">
            {{ customerDetails?.external_id ? customerDetails?.external_id : "-" }}
          </p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Allows Marketing Campaigns</h6>
            <p class="profile-title green-text " v-if ="customerDetails?.allow_marketing">
            Yes
          </p>
          <p class="profile-title red-text" v-else>
            No
          </p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Warehouse</h6>
          <p class="profile-title">{{ customerDetails?.warehouse ? customerDetails?.warehouse.name : "-" }}</p>
        </div>
        <div class="profile_field_data">
          <h6 class="profile-title">Churn prediction</h6>
          <p class="profile-title capitalize">
            {{ customerDetails?.churn_prediction ? customerDetails?.churn_prediction.churn_status : "-" }}
            {{ customerDetails?.churn_prediction ? "(" + customerDetails?.churn_prediction?.churn_period[0] + " - " : "" }}
            {{ customerDetails?.churn_prediction ? customerDetails?.churn_prediction?.churn_period[1] + ")" : "" }}
          </p>
        </div>
      </div>
    </div>

    <div class="col-12" v-if="customerDetails">
      <div class="edit-button form-btns">
        <button
          type="submit"
          class="save-btn"
          @click.prevent="
            this.$router.push({
              name: 'EditCustomer',
              params: { customerId: customerDetails.id },
            })
          "
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.41999 18.5789C1.13948 18.5784 0.872062 18.4601 0.682993 18.2529C0.490439 18.0474 0.394758 17.7694 0.419993 17.4889L0.664993 14.7949L11.983 3.48091L15.52 7.01691L4.20499 18.3299L1.51099 18.5749C1.47999 18.5779 1.44899 18.5789 1.41999 18.5789ZM16.226 6.30991L12.69 2.77391L14.811 0.652906C14.9986 0.465129 15.2531 0.359619 15.5185 0.359619C15.7839 0.359619 16.0384 0.465129 16.226 0.652906L18.347 2.77391C18.5348 2.96147 18.6403 3.216 18.6403 3.48141C18.6403 3.74681 18.5348 4.00134 18.347 4.18891L16.227 6.30891L16.226 6.30991Z"
              fill="#fff"
            />
          </svg>
          Edit
        </button>
        <button type="button" class="cancle-btn" @click="csvExport()">
          GDPR extract
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { API_URL } from "@/Core/helpers/utils";
import { mapActions, mapGetters } from "vuex";
import { subject } from "@casl/ability";
import {
  CUSTOMER_DETAILS,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  GET_GDPR_DATA,
} from "@/Core/store/action-types";
// import { deleteItem } from "@/Core/helpers/gridUtils";
import { RESOURCE_NAME } from "../customer.vars";
import CustomModel from "../../Core/CustomModel.vue";
import router from "../../Router";
export default {
  name: "CustomerDetails",
  components: {
    CustomModel,
  },
  props: {
    profileDetails: {
      type: Object,
    },
  },
  data() {
    return {
      deleteCustomerModelId: "deleteCustomerModel",
      loadindShow:false,
      hasPermission: this.$ability.can(
            "delete",
            subject(RESOURCE_NAME, {})
          ),
      warehouses: [],
      selectedWarehouse: null,
    };
  },
  computed: {
    ...mapGetters("customer", ["customerDetails"]),
    ...mapGetters("org", ["default_currency"]),
  },
  methods: {
    ...mapActions("customer", [
      CUSTOMER_DETAILS,
      UPDATE_CUSTOMER,
      DELETE_CUSTOMER,
      GET_GDPR_DATA,
    ]),

    showConnectWarehouseDialog() {
      this.fetchWarehouses();
			window.$(`#connectWarehouseModel`).modal("toggle");
		},

    async fetchWarehouses() {
      const token = this.$store.getters['user/userProfile'].access;
      try {
        const response = await axios.get(`${API_URL}/api/v1/national-warehouses/?member_id=${this.customerDetails.external_id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.warehouses = response.data;
        console.log("Warehouses===>", this.warehouses);
      } catch (error) {
        console.error('Error fetching warehouses:', error);
      }
    },

    async connectToWarehouse() {
      const token = this.$store.getters['user/userProfile'].access;
      try {
        const response = await axios.patch(`${API_URL}/api/v1/national-warehouses/`, {
          member_id: this.customerDetails.external_id,
          warehouse_id: this.selectedWarehouse
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log("Connection successful:", response.data);
        this.$alertify.notify("Member connected to warehouse successfully.", "success", 3);
        window.$(`#connectWarehouseModel`).modal("hide"); // Close the modal
        this.InItCustomerDetails();
      } catch (error) {
        console.error('Error connecting to warehouse:', error);
        this.$alertify.notify("Failed to connect member to warehouse.", "error", 3);
      }
    },

    //get customer details
    InItCustomerDetails() {
      if (this?.$route?.params?.customerId && this.$route.params.customerId != "") { 
        const params={
          customerId:this.$route.params.customerId,
          currency:this.default_currency
        }
        this.loadindShow = true
        this[CUSTOMER_DETAILS](params).then(() => {
          // this.hasPermission = this.$ability.can(
          //   "delete",
          //   subject(RESOURCE_NAME, this.customerDetails)
          // );
        }).finally(()=>this.loadindShow=false)
      }
    },
    // deleteCustomer(item) {
    //   console.log(this.$ability.can("delete", subject(RESOURCE_NAME, item)));
    //   deleteItem(
    //     this.$alertify,
    //     this.$ability,
    //     this[DELETE_CUSTOMER],
    //     RESOURCE_NAME,
    //     item
    //   );
    // },

    //delete customer
    deleteCustomer(checkStatus) {
      if (checkStatus) {
        this.csvExport();
      }
      window.$(`#${this.deleteCustomerModelId}`).modal("hide");
      this[DELETE_CUSTOMER](this.customerDetails.id).then(() => {
        this.$alertify.notify(
          `${this.customerDetails.name} successfully removed.`,
          "success",
          3
        );
        router.push("/Customers");
      });
    },
    //export GDPR data
    csvExport() {
      this[GET_GDPR_DATA](this.customerDetails.id).then((resp) => {
        let arrData = [
          {
            personalDetails: [resp.member_profile],
            receipts: resp.orderlines,
            campaigns: resp.campaigns,
          },
        ];
        console.log("ArrrrData===>", arrData);
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += [
          "Personal Details,",
          "\n" + Object.keys(arrData[0].personalDetails[0] || {}).join(","),
          ...arrData[0].personalDetails.map((item) =>
            Object.values(item).join(",")
          ),
        ].join("\n");
        if (arrData[0].receipts.length > 0) {
          csvContent += [
            "\n\n\n\n" + "Order History,",
            "\n" + Object.keys(arrData[0].receipts[0] || {}).join(","),
            ...arrData[0].receipts.map((item) => Object.values(item).join(",")),
          ].join("\n");
        }
        if (arrData[0].campaigns.length > 0) {
          csvContent += [
            "\n\n\n\n" + "Campaigns,",
            "\n" + Object.keys(arrData[0].campaigns[0] || {}).join(","),
            ...arrData[0].campaigns.map((item) =>
              Object.values(item).join(",")
            ),
          ].join("\n");
        }
        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", `GDPR_${this.customerDetails.id}.csv`);
        link.click();
      });
    },
    //open delete dialog
    showDeleteDialog() {
      window.$(`#${this.deleteCustomerModelId}`).modal("toggle");
    },
  },
  mounted() {
    this.InItCustomerDetails();
  },
};
</script>
<style scoped>

.data-heading-wrp .data-heading-btn button {
    background: #5e66fa;
    border-radius: 4px;
    font-style: normal;
    font-size: 15px;
    line-height: 150%;
    border: 0;
    color: #fff;
    font-weight: 400;
    padding: 8px 10px;
    margin: 30px 20px 0px;
}

</style>