<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <MainMenu />
    <TopBar
      title="Profile"
      :author="{
        home: 'Home',
        cart: 'Profile',
      }"
    />
    <CustomModel
      v-if="userProfile"
      :modalId="logoutModelID"
      :item="userProfile"
      :onDelete="LogoutUser"
      :resourseName="'User'"
      actionType="Logout"
    />
    <!-- Page Content-->
     <div class="main-wrp">
    <div class="contant-box-main profile-edit-box">
      <div class="profile-details">
        <div class="profile-details-left">
          <div class="pd-image">
            <img
              :src="
                generateAvatar(
                  userProfile?.email?.slice(0, 1).toUpperCase(),
                  '#5e66fa',
                  '#F0F1F2'
                )
              "
              alt="profile image--img"
              v-if="userProfile.profile_image_url == null"
            />
            <img :src="userProfile.profile_image_url" alt="profile-image" />
            <span class="fro-profile_main-pic-change">
              <label for="file-input">
                <CameraIcon />
              </label>
            </span>
          </div>
         
          <input
            type="file"
            id="file-input"
            name="myImage"
            accept="image/x-png,image/gif,image/jpeg"
            style="display: none"
            @change="onFileChange"
          />
          <div class="pd-text">
            <h3>Email</h3>
            <span>{{ userProfile.email }}</span>
            <p>{{ getUserRole(userProfile) }}</p>
          </div>
        </div>
        <button @click="showLogoutModel" class="logout-btn">
          <LogOutIcon /> Log out
        </button>
      </div>
      <div class="contant-details">
        <h6>
          Change Password
        </h6>
        <Form
          @submit="onSubmit"
          :validation-schema="schema"
          v-slot="{ errors }"
          action="#"
        >
          <div class="row">
            <div class="col-6">
              <div
                class="form-group"
                :class="{ 'has-error': errors.old_password }"
              >
                <label class="form-label w-100"
                  >Current password<span class="required-field text-danger"
                    >*</span
                  ></label
                >
                <div class="position-relative">
                <Field
                  as="input"
                  :type="[showPassword.indexOf(1) > 0 ? 'text' : 'password']"
                  class="form-control"
                  name="old_password"
                  placeholder="Enter current password"
                />
                <div class="control">
                  <button type="button" class="button" @click="toggleShow(1)">
                    <span
                      class="eye-iocon"
                      :class="{
                        'eye-off': showPassword.indexOf(1) > 0,
                        'eye-on': showPassword.indexOf(1) < 0,
                      }"
                    >
                      <img src="@/../public/assets/images/eye.svg" alt="" />
                    </span>
                  </button>
                </div>
              </div>
                <div class="form-control-feedback">
                  {{ errors.old_password }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div
                class="form-group"
                :class="{ 'has-error': errors.new_password }"
              >
                <label class="form-label w-100"
                  >New password<span class="required-field text-danger"
                    >*</span
                  ></label
                >
                <div class="position-relative">
                <Field
                  as="input"
                  :type="[showPassword.indexOf(2) > 0 ? 'text' : 'password']"
                  class="form-control"
                  name="new_password"
                  placeholder="Enter new password"
                />
                <div class="control">
                  <button type="button" class="button" @click="toggleShow(2)">
                    <span
                      class="eye-iocon"
                      :class="{
                        'eye-off': showPassword.indexOf(2) > 0,
                        'eye-on': showPassword.indexOf(2) < 0,
                      }"
                    >
                      <img src="@/../public/assets/images/eye.svg" alt="" />
                    </span>
                  </button>
                </div>
              </div>
                <div class="form-control-feedback">
                  {{ errors.new_password }}
                </div>
              </div>
            </div>
          </div>
            <div class="row">
              <div class="col-6">
                <div
                  class="form-group"
                  :class="{ 'has-error': errors.confirm_password }"
                >
                  <label class="form-label w-100"
                    >Confirm password<span class="required-field text-danger"
                      >*</span
                    ></label
                  >
                  <div class="position-relative">
                  <Field
                    as="input"
                    :type="[showPassword.indexOf(3) > 0 ? 'text' : 'password']"
                    class="form-control"
                    name="confirm_password"
                    placeholder="Enter confirm password"
                  />
                  <div class="control">
                    <button type="button" class="button" @click="toggleShow(3)">
                      <span
                        class="eye-iocon"
                        :class="{
                          'eye-off': showPassword.indexOf(3) > 0,
                          'eye-on': showPassword.indexOf(3) < 0,
                        }"
                      >
                        <img src="@/../public/assets/images/eye.svg" alt="" />
                      </span>
                    </button>
                  </div>
                </div>
                  <div class="form-control-feedback">
                    {{ errors.confirm_password }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-btns m-0">
                  <button type="submit" class="save-btn">Save</button>
                </div>
              </div>
            </div>
        </Form>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import { mapActions, mapGetters } from "vuex";

import {
  CHANGE_OWN_PASSWORD,
  LOGOUT,
  CHANGE_PROFILE_IMAGE,
} from "@/Core/store/action-types";
import { UserRoleMixin } from "@/Core/mixins/UserRoleMixin";
import MainMenu from "../Menu/MainMenu.vue";
import TopBar from "../Menu/TopBar.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import Loading from "vue-loading-overlay";
import LogOutIcon from "../icons/logout.vue";
import CameraIcon from "../icons/camera.vue";
import CustomModel from "../Core/CustomModel.vue";

export default {
  name: "Profile",
  components: {
    Form,
    Field,
    MainMenu,
    TopBar,
    Loading,
    LogOutIcon,
    CameraIcon,
    CustomModel,
  },
  mixins: [UserRoleMixin],
  methods: {
    ...mapActions("user", [CHANGE_OWN_PASSWORD, LOGOUT, CHANGE_PROFILE_IMAGE]),
    onSubmit(values, actions) {
      let form = { values, actions, userId: this.userProfile.id };
      this[CHANGE_OWN_PASSWORD](form)
        .then(() => {
          this.$alertify.notify("Password successfully updated.", "success", 3);
          actions.resetForm();
        })
        .catch(() => {}); //stay on a page
    },
    showLogoutModel() {
      window.$(`#${this.logoutModelID}`).modal("toggle");
    },
    LogoutUser() {
      window.$(`#${this.logoutModelID}`).modal("hide");
      this[LOGOUT]({ ability: this.$ability }).then(()=>{
        this.$ability.update([])
        this.$router.push('/login')
      }).catch(() => {
        this.$alertify.notify("Action Forbidden", "error", 3);
      });
    },
    toggleShow(id) {
      let i = this.showPassword.indexOf(id);
      if (i > 0) {
        this.showPassword.splice(i, 1);
      } else {
        this.showPassword.push(id);
      }
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      else {
        if (files[0]) {
          const file = files[0];
          console.log(file.size);
          var pattern = /image-*/;
          if (!file.type.match(pattern)) {
            this.$alertify.notify("Invalid format", "error", 3);
            return;
          } else if (file.size > 512 * 512) {
            this.$alertify.notify("Image must be less then 256KB", "error", 3);
            return;
          } else {
            this.createImage(files[0]);
          }
        }
      }
    },
    createImage(file) {
      // var image = new Image();
      var reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this[CHANGE_PROFILE_IMAGE](e.target.result)
          .then(() => {
            this.$alertify.notify("Image successfully updated.", "success", 3);
          })
          .catch(() => {
            this.$alertify.notify("Action Forbidden", "error", 3);
          });
      };
      reader.readAsDataURL(file);
    },
    generateAvatar(text, foregroundColor, backgroundColor) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = 150;
      canvas.height = 150;

      // Draw background
      context.fillStyle = backgroundColor;
      context.fillRect(0, 0, canvas.width, canvas.height);

      // Draw text
      context.font = "bold 100px Assistant";
      context.fillStyle = foregroundColor;
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillText(text, canvas.width / 2, canvas.height / 2);

      return canvas.toDataURL("image/png");
    },
  },
  data() {
    return {
      showPassword: [0],
      isLoading: true,
      fullPage: true,
      logoutModelID: "logoutuserModel",
      image: "",
    };
  },
  created() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
  computed: {
    ...mapGetters("user", { userProfile: "userProfile" }),
  },
  setup() {
    const schema = yup.object().shape({
      old_password: yup
        .string()
        .min(6, "Password must be at least 6 characters")
        .required("Current password is required"),
      new_password: yup
        .string()
        .min(6, "Password must be at least 6 characters")
        .required("New password is required"),
      confirm_password: yup
        .string()
        .oneOf([yup.ref("new_password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      detail: yup
        .string() // use it for backend errors
        .nullable()
        .notRequired(),
    });

    return {
      schema,
    };
  },
};
</script>
