//Main styles
import "./styles/styles.scss";

//Header
import "./styles/Header/header.scss";

//Sidebar
import "./styles/Sidebar/sidebar.scss";

//Dashboard
import "./styles/dashboard/dashboard.scss";