<template>
  <div class="contant-box-main customer-list-box p-0">
    <div class="h-100">
      <div class="contant-header">
        <h6>
          <img
            src="@/../public/assets/images/user-square.svg"
            alt="user-square"
          />Customers Need Restock
        </h6>
        <div>
          <!-- Replaced dropdown with time period display -->
          <span class="date-display" v-if="restock_period_start && restock_period_end">
            {{ restock_period_start }} - {{ restock_period_end }}
            <img
            src="@/../public/assets/images/calendar.svg"
            alt="calendar"
          />
          </span>
        </div>
      </div>
      <div class="contant-details h-100">
        <div class="overflow-auto position-relative" :class="loadindShow ? 'h-100' : ''">
          <div class="loader-main" v-if="loadindShow">
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <table class="table" id="my-table">
            <thead>
              <tr>
                <th>Customer ID</th>
                <th>Company Name</th>
                <th>Last Purchase Date</th>
                <th>Restock Purchase Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="customer in customer_restock" :key="customer.id">
                <td class="font-light-text" width="15%">
                  {{ customer.external_id }}
                </td>
                <td class="font-text" width="25%">
                  {{ customer.company_name }}
                </td>
                <td class="font-text" width="20%">
                  {{ customer.last_purchase_date }}
                </td>
                <td class="font-text" width="20%">
                  {{ customer.predicted_restock_date }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="contant-footer d-flex justify-content-end">
      <a
        role="button"
        data-toggle="modal"
        data-target="#customerRestockNowModel"
        class="view-more-btn"
        @click="initCustomerNeedRestock(true)"
      >
        View All
        <img src="@/../public/assets/images/right-sky.png" alt="" />
      </a>
    </div>
  </div>
  <!-- most valuable customer list model -->
  <div
    class="modal fade"
    id="customerRestockNowModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="customerRestockNowModelTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="valubaleCustomerListModelTitle">
            Customers Need Restock
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="contant-details">
            <form class="profile-form">  
                  <div class="contant-header">
                    <h6>
                      <img
                      src="@/../public/assets/images/user-square.svg"
                      alt="user-square"
                    />
                      Customer Restock Now
                    </h6>
                    <button
                      type="button"
                      class="save-btn"
                      @click="generateCSV(customer_restock)"
                    >
                      Export List
                    </button>
                  </div>
            </form>
          </div>
          <div class="contant-details">
            <form class="profile-form">
              <div class="model_table">
                    <div class="table-wrp overflow-auto position-relative">
                      <div class="loader-main" v-if="loadindShow">
                        <div
                          class="spinner-border"
                          role="status"
                          style="width: 36px; height: 36px; color: #5e66fa"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div> 
                      <table class="table my-table-wrp table-sm" id="my-table">
                        <thead>
                          <tr>
                            <th width="10%">Customer ID</th>
                            <th width="25%">Company Name</th>
                            <th width="25%">Last Purchase Date</th>
                            <th width="25%">Restock Purchase Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="customer in all_customer_restock ? all_customer_restock.slice(10 * (page_model - 1), page_model * 10) : []"
                            :key="customer.id"
                          >
                            <td class="font-light-text">
                              {{ customer.external_id }}
                            </td>
                            <td class="font-text">
                              {{ customer.company_name }}
                            </td>
                            <td class="font-text">
                              {{ customer.last_purchase_date }}
                            </td>
                            <td class="font-text">
                              {{ customer.predicted_restock_date }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      :page="page_model"
                      :totalPages="all_customer_restock ? Array(Math.ceil(all_customer_restock.length / 10)).fill(0).map((e, i) => i + 1) : []"
                      :name="'Customers'"
                      :count="all_customer_restock ? all_customer_restock.length : 0"
                      :incrementpage="incrementpage_model"
                      :decrementpage="decrementpage_model"
                      :setpage="setpage_model"
                      :perpage="10"
                    />
                  </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <div class="form-btns m-0">
            <button
              type="button"
              class="cancle-btn"
              @click="oncancel"
              data-dismiss="modal"
              aria-label="Close"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { GET_CUSTOMER_RESTOCK } from "@/Core/store/action-types";
import Pagination from "../../Core/Pagination.vue";

export default {
  name: "CustomerRestock",
  components: {
    Pagination,
  },
  data() {
    return {
      page_model: 1,
      customer_restock: [],
      all_customer_restock: [],
      loadindShow: false,
      restock_period_start: '',
      restock_period_end: '',
      visiblePages: 5, // Number of visible pages in pagination
    };
  },
  methods: {
    ...mapActions("customer", [GET_CUSTOMER_RESTOCK]),
    initCustomerNeedRestock(isAll = false) {
      this.loadindShow = true
      let params = {
        duration:
          this.customerTime == "month"
            ? 30
            : this.customerTime == "week"
            ? 7
            : this.customerTime == "quater"
            ? 90
            : this.customerTime == "half a year"
            ? 180
            : 365,
        all: isAll ? isAll : null,
      };
      this[GET_CUSTOMER_RESTOCK](params)
      .then((result) => {
        if(isAll) this.all_customer_restock = result.customers;
        else this.customer_restock = result.customers;
        this.restock_period_start = result.restock_period_start;
        this.restock_period_end = result.restock_period_end;
      })
      .catch(() => {
        this.all_customer_restock = [];
        this.restock_period_start = '';
        this.restock_period_end = '';
      })
      .finally(() => (this.loadindShow = false));
    },
    generateCSV() {
      let csvContent = "data:text/csv;charset=utf-8,";
      // Header
      csvContent += Object.keys(this.all_customer_restock[0] || {}).map(e => `"${e}"`).join(",") + "\n";

      // Data
      this.all_customer_restock.forEach(item => {
        const row = Object.values(item).map(field => 
          `"${field.toString().replace(/"/g, '""')}"` // Escape double quotes and wrap each field in double quotes
        ).join(",");
        csvContent += row + "\n";
      });

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", `customer-need-restock.csv`);
      link.click();
    },
    onTimeChange() {
      this.initCustomerNeedRestock();
    },
    incrementpage_model() {
      this.page_model = this.page_model + 1;
    },
    decrementpage_model() {
      this.page_model = this.page_model - 1;
    },
    setpage_model(page) {
      this.page_model = page;
    },
  },
  mounted() {
    this.initCustomerNeedRestock();
  },
};
</script>
<style scoped>
select#adminfilter {
  border-radius: 3px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #cacbcd;
  border-radius: 4px;
  font-weight: 300;
}

select#adminfilter:hover {
  border: 1px solid #000;
}
.view-more-btn {
  color: #5f66fa !important;
  font-weight: 500;
}
.customer-list-box {
  height: 100%;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.date-display {
  font-size: 14px; /* Adjust the font size as needed */
  color: #5e66fa; /* Example color, adjust as needed */
}

.table td {
    max-width: 150px; /* Set a max width for the cells */
    overflow: hidden; /* Hide overflow */
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
}
</style>