<template>
  <div class="contant-box-main customer-list-box">
    <div class="h-100">
      <div class="contant-header">
        <h6>
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAPCAYAAADQ4S5JAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFESURBVHgBhVHLUcNADJVkkwk30wElhA5CBxRAfjMJww1TQUIFhGty2RAOHCkhVIA7wHTg4ULi2BJaEzPGxsmb2bGsfW/03gqhhO4ovkHENgh4gmIeZ41F8R7/kK/iN/209AS7Vlar6CznUF50RnHfEljYtwR7BPjW9uzUioAIe2ojeJo3H/LectacqolArbUrAkUEdRD0KgJhWWmiVme49n9tZrW0EMHAvtC4Cy37QltkQQUGjBAxSKgKv0i2cIs/fV88WCchM6zscEL+gBKw6BeRxtrxSolDpU3yBdKP980Eie7VcyCC7c/46EQJKI5uHNC+nukON5NsQv/665TZeVfvZjlvDOAfXA43xu6JOT0nEWdsx2636R3UwE0avj5A5DhOj4ThQm2sns1xWCcwBiMkeLFcFxCnwskrHECapgsiN/wGgX6SW5zKhKcAAAAASUVORK5CYII="
            alt=""
          />Customer Restock Now
        </h6>
        <div>
          <!-- Replaced dropdown with time period display -->
          <span class="date-display" v-if="restock_period_start && restock_period_end">
            {{ restock_period_start }} - {{ restock_period_end }}
          </span>
        </div>
      </div>
      <div class="contant-details h-100">
        <div class="overflow-auto position-relative" :class="loadindShow ? 'h-100' : ''">
          <div class="loader-main" v-if="loadindShow">
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <table class="table" id="my-table">
            <thead>
              <tr>
                <th>Customer ID</th>
                <th>Company Name</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="customer in customer_restock_now" :key="customer.id">
                <td class="font-light-text" width="15%">
                  {{ customer.external_id }}
                </td>
                <td width="40%" class="font-text">
                  {{ customer.company_name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="contant-footer d-flex justify-content-end">
      <a
        role="button"
        data-toggle="modal"
        data-target="#customerRestockNowModel"
        class="view-more-btn"
        @click="initCustomerRestockNow(true)"
      >
        View All
        <img src="@/../public/assets/images/right-sky.png" alt="" />
      </a>
    </div>
  </div>
  <!-- most valuable customer list model -->
  <div
    class="modal fade"
    id="customerRestockNowModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="customerRestockNowModelTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="valubaleCustomerListModelTitle">
            Customer Restock Now
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-12">
                  <div class="contant-header">
                    <h6>
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAOCAYAAAAmL5yKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHXSURBVHgBjVJBbtpQEJ0Z29+N2lS+QeEGcIKSE5TuqihBlhrSRReQEwROELproBWmRN2GngBuEHIDjkClVBQb/8kMwY4SJQqz8HyP/3vz3owRNrEf3pQc3zTAQkFeB8OeiWCLIH18ChcF1zNjtFDVCiL0D+txf2sCz3dOAWFOTloenps9YPgmJOHnr/xuKwLpGQhoHn3fmembZRxpXi7/F18icNdwtteiu1o7Tk4t4DWBPZPy7OLHziS7WKvHDUktURowYGfY9U5yBb+6fktlA3BLwJdyDojjjxn44CipCLDDAFO5M0DgZu142YJ7C0LSM02itLjiuEyvvWLUezPNfZKtaJbN7EmzUI5TZnyfW6iGHLw1q4ZNuemiE6T/ksnB0aKdWbApTMkRGxuLUikRQZQr2PWSS5XPyALgtmygQOSMhWTd+eKnP3pgUeaDmLbX81N/RDxmsCfD7quOFkNRZE1yxQwzlZ1Z2a/flDT/fmAv87cBa0QRzqXjH1FSySyqfBfM2EVzdfgl7uvPl8/gyUCey2N93PWTMyEMUXzLJv4iwwdjnIooLT9PsAntJIBQZzM4l3WDrnUx0hmlXlIlZjtjhMljYFYnzwk0rzgZZd90O3eYFG4Bk3jRLFulvBYAAAAASUVORK5CYII="
                        alt=""
                      />
                      Customer Restock Now
                    </h6>
                    <button
                      type="button"
                      class="save-btn"
                      @click="generateCSV()"
                    >
                      Export List
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-lg-12">
                  <div class="model_table">
                    <div class="table-wrp overflow-auto position-relative">
                      <div class="loader-main" v-if="loadindShow">
                        <div
                          class="spinner-border"
                          role="status"
                          style="width: 36px; height: 36px; color: #5e66fa"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div> 
                      <table class="table my-table-wrp table-sm" id="my-table">
                        <thead>
                          <tr>
                            <th width="10%">Customer ID</th>
                            <th width="30%">Company Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="customer in all_customer_restock_now ? all_customer_restock_now.slice(10 * (page_model - 1), page_model * 10) : []"
                            :key="customer.id"
                          >
                            <td class="font-light-text">
                              {{ customer.external_id }}
                            </td>
                            <td class="font-text">
                              {{ customer.company_name }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      :page="page_model"
                      :totalPages="all_customer_restock_now ? Array(Math.ceil(all_customer_restock_now.length / 10)).fill(0).map((e, i) => i + 1) : []"
                      :name="'Customers'"
                      :count="all_customer_restock_now ? all_customer_restock_now.length : 0"
                      :incrementpage="incrementpage_model"
                      :decrementpage="decrementpage_model"
                      :setpage="setpage_model"
                      :perpage="10"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <div class="form-btns">
            <button
              type="button"
              class="cancle-btn"
              @click="oncancel"
              data-dismiss="modal"
              aria-label="Close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { GET_CUSTOMER_RESTOCK_NOW } from "@/Core/store/action-types";
import Pagination from "../../Core/Pagination.vue";

export default {
  name: "CustomerRestockNow",
  components: {
    Pagination,
  },
  data() {
    return {
      page_model: 1,
      customer_restock_now: [],
      all_customer_restock_now: [],
      loadindShow: false,
      restock_period_start: '',
      restock_period_end: '',
    };
  },
  methods: {
    ...mapActions("customer", [GET_CUSTOMER_RESTOCK_NOW]),
    initCustomerRestockNow(isAll = false) {
      this.loadindShow = true
      let params = {
        duration:
          this.customerTime == "month"
            ? 30
            : this.customerTime == "week"
            ? 7
            : this.customerTime == "quater"
            ? 90
            : this.customerTime == "half a year"
            ? 180
            : 365,
        all: isAll ? isAll : null,
      };
      this[GET_CUSTOMER_RESTOCK_NOW](params)
      .then((result) => {
        if(isAll) this.all_customer_restock_now = result.customers;
        else this.customer_restock_now = result.customers;
        this.restock_period_start = result.restock_period_start;
        this.restock_period_end = result.restock_period_end;
      })
      .catch(() => {
        this.all_customer_restock_now = [];
        this.restock_period_start = '';
        this.restock_period_end = '';
      })
      .finally(() => (this.loadindShow = false));
    },
    onTimeChange() {
      this.initCustomerRestockNow();
    },
    incrementpage_model() {
      this.page_model = this.page_model + 1;
    },
    decrementpage_model() {
      this.page_model = this.page_model - 1;
    },
    setpage_model(page) {
      this.page_model = page;
    },
  },
  mounted() {
    this.initCustomerRestockNow();
  },
};
</script>
<style scoped>
select#adminfilter {
  border-radius: 3px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #cacbcd;
  border-radius: 4px;
  font-weight: 300;
}

select#adminfilter:hover {
  border: 1px solid #000;
}
.view-more-btn {
  color: #5f66fa !important;
  font-weight: 500;
}
.customer-list-box {
  height: 100%;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.date-display {
  font-size: 14px; /* Adjust the font size as needed */
  color: #5e66fa; /* Example color, adjust as needed */
}
</style>