<template>
    <div class="contant-box-main customer-list-box">
        <div class="h-100">
            <div class="contant-header">
                <h6>
                    <img src="@/../public/assets/images/star-icon.png" alt="" /> Articles Low Stock (Central Warehouse)
                </h6>
            </div>
            <div class="contant-details h-100">
                <div class="overflow-auto position-relative" :class="loadindShow ? 'h-100' : ''">
                    <div class="loader-main" v-if="loadindShow">
                        <div class="spinner-border" role="status" style="width: 36px; height: 36px; color: #5e66fa">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <table class="table" id="my-table">
                        <thead>
                            <tr>
                                <th>Article ID</th>
                                <th>Article Name</th>
                                <th>Warehouse Code</th>
                                <th>Stock</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(article) in displayedArticles" :key="article.article_id">
                          <td>{{ article.sku }}</td>
                          <td>{{ article.name }}</td>
                          <td>{{ article.warehouse_code }}</td>
                          <td>{{ article.stock }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      <div class="contant-footer d-flex justify-content-end" v-if="articles.length > 10">
        <a
            role="button"
            data-toggle="modal"
            data-target="#articlesLowStockCentralWareHouseModel"
            class="view-more-btn"
        >
          View All
          <img src="@/../public/assets/images/right-sky.png" alt=""
          /></a>
      </div>
    </div>
  <div
      class="modal fade"
      id="articlesLowStockCentralWareHouseModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="valubaleCustomerListModelTitle"
      aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="restockNowArticleListModelTitle">
            Articles Low Stock (Central Warehouse)
          </h5>
          <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-12">
                  <div class="contant-header">
                    <h6>
                      <img
                          src="@/../public/assets/images/star-icon.png"
                          alt=""
                      />
                      Articles Low Stock (Central Warehouse)
                    </h6>
                    
                    <button
                        type="button"
                        class="save-btn"
                    >
                      Export List
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-lg-12">
                  <div class="model_table">
                    <div class="table-wrp overflow-auto position-relative">
                      <div class="loader-main" v-if="loadindShow">
                        <div
                            class="spinner-border"
                            role="status"
                            style="width: 36px; height: 36px; color: #5e66fa"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                      <table class="table my-table-wrp table-sm" id="my-table">
                        <thead>
                        <tr>
                          <th>Article ID</th>
                          <th>Article Name</th>
                          <th>Warehouse Code</th>
                          <th>Stock</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr

                            v-for="article in allArticles? allArticles.slice(
                                    10 * (page_model - 1),
                                    page_model * 10
                                  )
                                : []"
                            :key="article.article_id"
                            :class="
                                checkedFilter(article.article_id)
                                  ? 'active-row selected'
                                  : ''
                              "
                            >
                          <td>{{ article.sku }}</td>
                          <td>{{ article.name }}</td>
                          <td>{{ article.warehouse_code }}</td>
                          <td>{{ article.stock }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                        :page="page_model"
                        :totalPages="
                          allArticles
                            ? Array(Math.ceil(allArticles.length / 10))
                                .fill(0)
                                .map((e, i) => i + 1)
                            : []
                        "
                        :name="'Articles'"
                        :count="
                          allArticles ? allArticles.length : 0
                        "
                        :incrementpage="incrementpage_model"
                        :decrementpage="decrementpage_model"
                        :setpage="setpage_model"
                        :perpage="10"
                    />
                  </div>
                </div
                ></div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <div class="form-btns">
            <button
                type="button"
                class="cancle-btn"
                @click="oncancel"
                data-dismiss="modal"
                aria-label="Close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { API_URL } from "@/Core/helpers/utils";
import Pagination from "../../Core/Pagination.vue";

export default {
    name: "ArticlesLowStockCentralWarehouse",
    components : {
        Pagination       
      },
    data() {
        return {
            articles: [],
            allArticles: [],
            loadindShow: false,
            page_model: 1,
            choosen_articles: [],
            isAllSelected: false,
        };
    },
  computed: {
    displayedArticles() {
      return this.articles.slice(0, 10); // Return the first 10 articles
    },
  },
    methods: {
        async fetchArticlesLowStockCentral() {
            const token = this.$store.getters['user/userProfile'].access;
            this.loadindShow = true; // Show loader
            try {
                const response = await axios.get(`${API_URL}/api/v1/warehouse/low-stock-articles/?threshold=20`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                console.log("response", response);
                this.articles = response.data.filter(article => article.warehouse_type === 'central/production');
                this.allArticles = this.articles;
            } catch (error) {
                console.error('Error fetching articles:', error.response ? error.response.data : error.message);
            } finally {
                this.loadindShow = false; // Hide loader
            }
        },
        onTimeChange() {
            this.fetchArticlesLowStockCentral();
        },
        viewAllArticles() {
            this.$nextTick(() => {
                window.$('#articlesLowStockCentralWareHouseModel').modal('show'); // Show the modal
            });
        },
        closeModal() {
            window.$('#articlesLowStockCentralWareHouseModel').modal('hide'); // Hide the modal
        },
      AddRemoveArticles(id) {
        this.isAllSelected = false;
        setTimeout(() => {
          if (this.choosen_articles.includes(id)) {
            this.choosen_articles = this.choosen_articles.filter(
                (article_id) => article_id != id
            );
          } else {
            this.choosen_articles.push(id);
          }
        }, 300);
      },
      checkedFilter(id) {
        if (this.choosen_articles.includes(id)) return true;
        else return false;
      },
      incrementpage_model() {
        this.page_model = this.page_model + 1;
      },
      // go to previous page in customer table in model
      decrementpage_model() {
        this.page_model = this.page_model - 1;
      },
      // go to perticular page in customer table in model
      setpage_model(page) {
        this.page_model = page;
      },
      AddRemoveAllArticles() {
        if (this.isAllSelected) {
          this.choosen_articles = [];
          this.isAllSelected = false;
        } else {
          this.choosen_articles = this.getAllArticleIDs();
          this.isAllSelected = true;
        }
      },

      getAllArticleIDs() {
        return this.allArticles.map((a) => a.article_id);
      },

      resetModalFields() {
        this.choosen_articles = [];
        this.isAllSelected = false;
        this.page_model = 1;
        this.loadindShow = false;
      },
    },
    mounted() {
      window.$('#articlesLowStockCentralWareHouseModel').on('hide.bs.modal', this.resetModalFields);
        this.fetchArticlesLowStockCentral();
    },
};
</script>

<style scoped>
select#adminfilter {
    border-radius: 3px;
    padding: 10px;
    border: 1px solid #cacbcd;
    font-weight: 300;
}

select#adminfilter:hover {
    border: 1px solid #000;
}

.customer-list-box {
    height: 100%;
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.view-more-btn {
    color: #5f66fa !important;
    font-weight: 500;
}

.table td {
    max-width: 150px; /* Set a max width for the cells */
    overflow: hidden; /* Hide overflow */
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
}

/* Optional: You can also set a specific width for the columns */
.table th:nth-child(1),
.table td:nth-child(1) {
    max-width: 100px; /* Adjust as needed */
}

.table th:nth-child(2), /* Article Name */
.table td:nth-child(2) {
    max-width: 150px; /* Adjust as needed */
}

.table th:nth-child(3), /* Warehouse Name */
.table td:nth-child(3) {
    max-width: 150px; /* Adjust as needed */
}
</style>
